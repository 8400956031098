    .center-th {
        text-align: center;
    }
    .left-th {
        text-align: left !important;
    }
    .charts-500 {
        height:500px;
    }
    .cursor td, .cursor th{
        cursor: pointer;
    }
    .asc {
        background-position: right;
        background-repeat: no-repeat;
        padding-right: 30px;
        background-image : url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==');
    }
    .desc {
        background-position: right;
        background-repeat: no-repeat;
        padding-right: 30px;
        background-image : url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=');
    }
    .row-second-level {
        background-color: #f5f5f5;
    }
    .row-third-level {
        background-color: #e5e5e5;
    }

    body {
        background: #e9ecf3 !important;
    }
