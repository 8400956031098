td,th {
    text-align : center;
}
td {
    white-space: nowrap;
}
.min-width {
    min-width : 100px;
}
.pointer {
    cursor : pointer;
}
.add-product:hover .add-product-plus {
    visibility : visible;
}
.add-product-plus{
    visibility : hidden;
}
.error {
    border : 1px solid red;
}

.change {
    background-color : #58b2ff;
}
.borrar td, .borrar th {
    background-color : red !important;
}
