
    .navbar{
        display: block;
    }
.prueba{
    pointer-events: none;
}

.tooltip{
    pointer-events: none;
}

td{
    cursor: pointer!important;
    position: relative;
    vertical-align: middle!important;
    -webkit-user-select: text !important;  /* Chrome 49+ */
    -moz-user-select: text !important;     /* Firefox 43+ */
    -ms-user-select: text !important;      /* No support yet */
    user-select: text !important;          /* Likely future */ 
}


td input{
    display: none;
}

.td-edicion{
    padding:0!important;
}

.edicion{
    visibility: hidden;
}

.edicion-able {
    position: absolute;
    display: block;
    top:0;
    left:0;
    margin: 0;
    height: 100%;
    width: 100%;
    border: none;
    text-align: center;
    padding: 0 5px 0 5px;
    box-sizing: border-box;
  }

  
  .modal-header{
    display: block;
}
